.filters {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
}

.exportPhonesButtonWrapper {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}