@import '../../utils/library';

.popupBackground {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #00000077;
  transition: all 0.3s ease-in-out;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 999;
  flex-direction: row;
  backdrop-filter: blur(5px);
}

.modal {
  display: grid;
  grid-template-rows: auto 1fr;
  height: auto;
  max-height: 90vh;
  box-shadow: 0 4px 9px rgba(77, 77, 77, 0.25);
  overflow: hidden;
  // background-color: #fff;
  border-radius: 5px;
  min-width: 300px;

  @include non-desktop {
    margin: 0 10px;
  }

  .modalBody {
    background-color: #fff;
    padding: 16px 30px;
    overflow: auto;
  }

  .modalHeader {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 70px;
    background-color: #d495aa;
    color: #fff;
    padding-inline-start: 35px;
    font-size: 1.3rem;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: 'Cocoa Gothic Small Caps';
    font-weight: 300;
  }

  .modalFooter {
    padding: 30px 40px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  // animations

  .slide-in-top {
    animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .slide-out-top {
    animation: slide-out-top 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  .fade-in {
    animation: fade-in 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .fade-out {
    animation: fade-out 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  @keyframes slide-in-top {
    0% {
      transform: translateY(-500px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-out-top {
    0% {
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      transform: translateY(-1000px);
      opacity: 0;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}