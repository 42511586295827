@import '../../../../utils/library';

.wrapper {
  @include sectionWrapper(#fff);

  h2 {
    @include sectionHeader();
    color: $secondary-color;
  }

  .header {
    position: absolute;
    z-index: 10;
    padding-top: 60px;
    font-weight: 200;
    font-family: 'Cocoa Gothic';
    background: transparent;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 30px;
  }
}

.newsWrapper {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  backdrop-filter: blur(20px);
  background: linear-gradient(180deg, #fff, transparent);

  @include non-desktop {
    padding-bottom: 30px;
  }
}

.swiperSlide {
  background-position: center;
  background-size: cover;

  img {
    @include non-desktop {
      padding-top: 100px;
    }
  }

  h3 {
    padding: 0 30px;
    color: $main-color;
    text-transform: uppercase;
    font-weight: 300;
  }

  p {
    padding: 0 30px;
    color: #494949;
    font-weight: 300;
    width: 100%;
    box-sizing: border-box;
  }

  .time {
    font-size: .7rem;
    color: #a5a5a5;
  }
}

.blurWrapper {
  backdrop-filter: blur(5px);
}

.newsSplash {
  @include non-desktop {
    width: 100%;
  }
}