@import '../../utils/library';

.wrapper {
    width: 100vw;
    height: 100vh;
    background-color: $main-color;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 50px;
        height: 50px;
    }
}