@import "../../../../utils/library";

.wrapper {
    @include sectionWrapper($secondary-color);
    background-image: url("../../../../assets/images/about-bg.jpg");
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
    text-align: justify;
    padding-bottom: 80px;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-color: $main-color;
        clip-path: polygon(0 0, 0 3%, 100% 0);
        pointer-events: none;
    }

    @include desktop {
        background-size: 120vh;
    }

    @include non-desktop {
        background-size: 110vw;
    }

    .aboutDescription {
        @include desktop {
            padding: 100px;
        }

        @include non-desktop {
            padding: 70px 30px 30px;
        }
    }

    .about {
        @include non-desktop {
            background-color: #00000044;
            // padding: 40px;
            margin: 0 -30px;
            backdrop-filter: blur(5px);
        }
    }

    em {
        // background-color: $main-color;
        // color: #fff;
        font-style: normal;
        padding: 2px;
    }

    h2 {
        @include sectionHeader();

        @include non-desktop {
            // margin-bottom: 150px;
        }
    }

    p {
        @include desktop {
            max-width: 500px;
            line-height: 1.5;
            font-size: 1rem;
        }

        @include non-desktop {
            line-height: 1;
            font-size: 1rem;
            font-weight: 300;
        }
    }
}

.slideIntLeft {
    @include slide-in-left;
}

.greeting {
    @include desktop {
        font-size: 2vw !important;
    }

    margin-bottom: 10px;
    color: $main-color;
    text-align: start;
    font-weight: 300;
}

.comment {
    position: relative;
    padding: 30px;
    background-color: #ffffffbb;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.02);
    color: $secondary-dark-color;

    h3 {
        @include desktop {
            font-size: 1.2vw;
        }
    }

    p {
        @include desktop {
            font-size: 1.2vw;
        }
    }
}

.comment:before {
    content: "";
    position: absolute;

    @include desktop {
        bottom: 20px;
        right: -20px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid #ffffffbb;
    }

    @include non-desktop {
        bottom: -20px;
        left: 20px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid #ffffffbb;
        clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0% 100%);
    }
}