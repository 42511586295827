@import '../../utils/library';

.swipe {
    position: absolute;
    z-index: 2;
    top: 47vh;
    right: 30px;
    animation: slide-left 2s cubic-bezier(.25, .46, .45, .94) both infinite;

    @include non-desktop {
        height: 80px;
    }
}

@keyframes slide-left {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(-100px);
        opacity: .0;
    }
}