.scroll {
    ::-webkit-scrollbar {
        width: 0;
        display: none;
    }

    ::-webkit-scrollbar-track {
        width: 0;
        display: none;
    }
}