@import '../../utils/library';

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 103;
    left: 0;
    right: 0;
    background-color: #00000077;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    backdrop-filter: blur(5px);
}

.closeIcon {
    position: absolute !important;
    z-index: 106;
    top: 30px;
    right: 30px !important;
    color: $main-color;
    background-color: #fff !important;
}

.body {
    padding: 30px 20px 40px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 30px;

    @include slide-in-top();

    p {
        font-size: 1.3rem;
        padding-bottom: 10px;
    }
}