@font-face {
  font-family: "Cocoa Gothic Small Caps";
  src: url("./assets/fonts/CocoGothicSmallCaps-Regular.eot") format("eot"),
    url("./assets/fonts/CocoGothicSmallCaps-Regular.woff") format("woff"),
    url("./assets/fonts/CocoGothicSmallCaps-Regular.woff2") format("woff2"),
    url("./assets/fonts/CocoGothicSmallCaps-Regular.eot") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cocoa Gothic";
  src: url("./assets/fonts/CocoGothic-Bold.ttf") format("ttf"),
    url("./assets/fonts/CocoGothic-Bold.eot") format("eot"),
    url("./assets/fonts/CocoGothic-Bold.woff2") format("woff2"),
    url("./assets/fonts/CocoGothic-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cocoa Gothic";
  src: url("./assets/fonts/CocoGothic-Heavy.ttf") format("ttf"),
    url("./assets/fonts/CocoGothic-Heavy.eot") format("eot"),
    url("./assets/fonts/CocoGothic-Heavy.woff2") format("woff2"),
    url("./assets/fonts/CocoGothic-Heavy.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cocoa Gothic";
  src: url("./assets/fonts/CocoGothic-Regular.ttf") format("ttf"),
    url("./assets/fonts/CocoGothic-Regular.eot") format("eot"),
    url("./assets/fonts/CocoGothic-Regular.woff2") format("woff2"),
    url("./assets/fonts/CocoGothic-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cocoa Gothic";
  src: url("./assets/fonts/CocoGothic-Light.ttf") format("ttf"),
    url("./assets/fonts/CocoGothic-Light.eot") format("eot"),
    url("./assets/fonts/CocoGothic-Light.woff2") format("woff2"),
    url("./assets/fonts/CocoGothic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cocoa Gothic";
  src: url("./assets/fonts/CocoGothic-UltraLight.ttf") format("ttf"),
    url("./assets/fonts/CocoGothic-UltraLight.eot") format("eot"),
    url("./assets/fonts/CocoGothic-UltraLight.woff2") format("woff2"),
    url("./assets/fonts/CocoGothic-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: "Cocoa Gothic";
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  scrollbar-width: auto;
  scrollbar-color: rgb(173, 61, 98) #f9bacf;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #f9bacf;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(173, 61, 98);
  border-radius: 10px;
  border: 3px solid #f9bacf;
}

.swiper-pagination-bullet {
  background-color: rgb(173, 61, 98);
  width: 15px;
  height: 15px;
}

h2 {
  font-family: 'Cocoa Gothic Small Caps';
  font-weight: 400;
}