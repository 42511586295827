@import '../../../../utils/library';

.wrapper {
  @include sectionWrapper($main-color);
  position: relative;

  h2 {
    @include sectionHeader();

    @include desktop {
      margin-top: 60px;
    }

    @include non-desktop {
      margin-bottom: 10px !important;
    }
  }

  h3 {
    display: flex;
    line-height: 1;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 19%, 100% 0, 100% 81%, 0% 100%);

    @include desktop {
      background-color: #fff;
      color: $main-color;
      max-width: 500px;
      margin: 60px !important;
      padding: 20px !important;
    }

    @include non-desktop {
      background-color: $main-color;
      margin: 0 !important;
      padding: 20px !important;
    }
  }

  .header {
    position: absolute;
    z-index: 10;
    padding-top: 60px;
    font-weight: 200;
    font-family: 'Cocoa Gothic';
    background: transparent;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 30px;
  }
}

.swiperSlide {
  width: 100vw;
  height: 100vh;

  box-sizing: border-box;
  color: #fff;

  @include desktop {
    background-size: 50vw;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
  }

  @include non-desktop {
    background-position-x: center;
    background-size: cover;
    padding: 60px 30px;

    h3 {
      text-transform: uppercase;
      padding-bottom: 30px;
    }

    p {
      line-height: 1.2;
      font-weight: 300;

      @include non-desktop {
        padding-bottom: 10px;
      }
    }

    .description {
      position: absolute;
      bottom: 60px;
      left: 30px;
      right: 30px;
      background-color: #00000055;
      padding: 30px;
      backdrop-filter: blur(5px);
    }
  }

  @include desktop {
    .description {
      margin: 60px !important;
      max-width: 500px;
      font-weight: 300;
      font-size: 1.2vw;
      line-height: 1.4;

      p {
        padding-bottom: 15px;
      }
    }
  }
}