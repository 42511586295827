@import "../../utils/library";

.wrapper {
  background-color: $main-color;
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("../../assets/images/legs.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50px bottom;

  @include desktop {
    justify-content: space-evenly;
  }

  @include non-desktop {
    justify-content: center;
    gap: 30px;
  }

  h1 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 4rem;

    @include non-desktop {
      display: none;
    }

    @include desktop {
      color: #fff;
    }
  }

  @include non-desktop {
    flex-direction: column;
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    h2 {
      @include desktop {
        display: none;
      }

      @include non-desktop {
        font-weight: 400;
        color: $main-color;
        text-align: center;
        text-transform: uppercase;
      }
    }

    @include desktop {
      width: 400px;
      padding: 60px;
    }

    @include non-desktop {
      width: 70vw;
      padding: 30px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.navActionsWrapper {
  display: flex;
  justify-content: space-between;

  button {
    text-transform:unset
  }
}
