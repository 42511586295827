.selected {
    background-color: #f9bacf !important;

    span {
        color: #fff;
    }

    svg {
        filter: invert(1);
    }
}

.selectedIcon {
    color: #fff;
}