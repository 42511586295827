@import '../../utils/library';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 300;

    @include desktop {
        min-width: 700px;
    }

    @include non-desktop {
        width: calc(100vw - 80px);
    }

    p {
        margin: 20px 0;
        font-size: 1.2rem;
    }

    a {
        color: $main-color;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 1.2rem;

        li {
            color: $main-color;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}

.subscriptionWrapper {
    margin: 10px 0;
}

.note {
    color: #9b9b9b;
    font-size: .87rem !important;
}