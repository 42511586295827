@import '../../../../utils/library';

.wrapper {
  @include sectionWrapper($secondary-color);
  background-image: url('../../../../assets//images/why_6.jpg');
  background-position: center;
  background-size: cover;

  h2 {
    @include sectionHeader();
  }

  .header {
    position: absolute;
    z-index: 10;
    padding-top: 60px;
    font-weight: 200;
    font-family: 'Cocoa Gothic';
    background: transparent;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 30px;
  }
}

.swiperSlide {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blurWrapper {
  backdrop-filter: blur(5px);
}