@import '../../utils/library';

.wrapper {
    display: grid;

    @include desktop {
        grid-template-columns: 1fr 1fr;
        min-width: 600px;
        gap: 40px;
        padding: 20px;
    }

    @include non-desktop {
        padding: 10px;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

.label {
    background-color: #fff;
    padding: 5px;
}