@import '../../utils/library';

.subscriptionWrapper {
    background-color: $main-color;
    color: #fff;
    font-weight: 300;
    width: 1080px;
    height: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    pointer-events: none;
    position: absolute;
    opacity: 0;
    background-image: url('../../assets//images/sub_bg.jpg');
    background-size: 50%;
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    transform: scale(2.5);
    font-size: 3rem !important;
    margin-top: -100px;

    h2 {
        font-size: 1.5rem;
        text-transform: uppercase;
    }
}

.downloadButton {
    position: absolute !important;
    z-index: 100;
    top: 70px;
    right: 15px;
}

.qrCode {
    background-color: #fff;
    padding: 20px;
}