@import '../../utils/library';

.wrapper {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    mix-blend-mode: soft-light;
    opacity: .5;
}

.stretching {
    @include desktop {
        background-size: 50vw;
        background-position: 20vw center;
    }

    @include non-desktop {
        background-size: 118vw;
        background-position: -16vw 23vh;
    }
}

.pointe {
    @include desktop {
        background-size: 70vw;
        background-position: 20vw center;
    }

    @include non-desktop {
        background-size: 247vw;
        background-position: -56vw 38vh;
    }
}

.intensive {
    @include desktop {
        background-size: 70vw;
        background-position: 20vw center;
    }

    @include non-desktop {
        background-size: 298vw;
        background-position: -101vw 36vh;

    }
}