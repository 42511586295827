@import '../../utils/library';

.wrapper {
    padding-top: 90px;

    @include desktop {
        margin: 0 30vw;    }
}

.formWrapper {
    margin: 30px;
    padding: 30px;
}

.alert {
    margin: 0 30px 30px;
}