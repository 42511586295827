@import '../../utils/library';

.wrapper {
  background-color: white;
  position: relative;
  transition: transform 0.5s ease;
  perspective: 1000px;
  background: rgb(255, 255, 255);
  background: linear-gradient(121deg, #5872b4 0%, #dee7ff 50%, #9bb6f8 100%);
  background-size: 200% 100%;
  background-position: right;
  margin: 10px 0;
  padding: 20px;
  text-shadow: 1px 1px 2px #00000044;
  min-height: 175px;

  @include desktop {
    width: 300px;
  }

  @include non-desktop {
    @include slide-in-left();
  }

  h2 {
    font-family: 'Cocoa Gothic';
    text-transform: uppercase;
  }
}

.wrapper:hover {
  transform: rotateY(20deg);
  background-position: left;
}

.wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

.clientCard {
  // background: linear-gradient(121deg, #5872b477 0%, #dee7ff 50%, #9bb6f877 100%);
}

.wrapper:hover::after {
  opacity: 1;
}

.price {
  position: absolute;
  font-size: 1rem;
  top: 50%;
  right: 20px;
}

.binIcon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #00000033;
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
  z-index: 10;
}

.removePopup {
  @include desktop {
    max-width: 500px;
  }

  @include non-desktop {
    margin: 30px;
  }
}

.qrCodeButton {
  color: #fff;
  position: absolute !important;
  z-index: 2;
  top: 10px;
  right: 10px;
  background-color: #00000033 !important;
  border-radius: 5px !important;
  padding: 1px !important;
}

.commentButton {
  position: absolute !important;
  z-index: 100;
  bottom: 10px;
  right: 10px !important;
}

.commentBody {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
}