@import '../../utils/library';

.wrapper {
    position: fixed;
    z-index: 100;
    color: #fff;
    background-color: rgb(37, 175, 37);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    @include slide-in-right;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    @include desktop {
        font-size: 1.1rem;
        padding: 10px 20px;
        gap: 20px;
        border-radius: 10px;
        right: 30px;
        bottom: 30px;
    }

    @include non-desktop {
        font-size: .9rem !important;
        padding: 5px 10px;
        gap: 5px;
        border-radius: 5px 0 0 5px;
        right: 0;
        bottom: 105px;
    }
}