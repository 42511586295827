@import '../../utils/library';

.wrapper {
    background-color: $main-color;
    background-repeat: no-repeat;
    box-sizing: border-box;
    height: 100%;
    padding-top: 50px;

    ul {
        margin: 0;
        padding: 20px;
        box-sizing: border-box;
    }
}

.noData {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 100px 0 0;
    font-weight: 300;
    font-size: 1.5rem;
}

.noDataBg {
    background-image: url('../../assets/images/sasha_shape.png');

    @include desktop {
        background-position: bottom left;
        background-size: 8vw;
    }

    @include non-desktop {
        background-position: bottom left;
        background-size: 40%;
    }
}

.dataBg {
    background-image: url('../../assets/images/legs_subs.png');

    @include desktop {
        background-position: bottom left;
        background-size: 30%;
    }

    @include non-desktop {
        background-position: bottom left;
        background-size: 90%;
    }
}