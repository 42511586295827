.nextButton {
    position: absolute;
    bottom: 50px;
    cursor: pointer;
    z-index: 200;
    left: calc((100% / 2) - 25px);
    animation: heartbeat 1.5s ease-in-out infinite both;
    background-color: #00000044;
    border-radius: 50%;
    padding: 7px;
  }