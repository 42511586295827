@import '../../utils/library';

.wrapper {
    background-color: #bbc0dd;
    height: 100vh;
    padding: 30px;
    box-sizing: border-box;
    overflow: auto;
    background-image: url('../../assets/images/basket_splash.jpg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &>h2 {
        @include sectionHeader();
        margin-bottom: 10px !important;
    }

    .items {
        max-height: 70vh;
        overflow: auto;
        padding-bottom: 20px;
        border-bottom: 1px solid #ffffff77;
        color: #fff;

        &::-webkit-scrollbar {
            width: 6px;
          }          

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ffffff33;
            border-radius: 10px;
            border: none;
        }
    }

    .sum {
        color: #fff;
        padding: 20px 0;

        span {
            font-weight: bold;
        }
    }
}