@import '../../../../utils/library';

.root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.appBar {
    background-color: $main-color !important;
}

.toolBar {
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: space-between;
}

.title {
    font-weight: 200;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.contextMenu{
    padding: 10px;
    .fullName {
        padding: 5px 20px 15px;
        white-space: nowrap;
        color: #2e2e2e;
        font-weight: 400;
    }
}

.photo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}