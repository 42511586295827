@import '../../../../../../utils/library';

.wrapper {
    height: 100vh;

    h3 {
        padding-bottom: 20px;
        text-transform: uppercase;
        text-align: center;
        // text-shadow: 0px 15px 5px rgba(0, 0, 0, 0.1),
        //     10px 20px 5px rgba(0, 0, 0, 0.05),
        //     -10px 20px 5px rgba(0, 0, 0, 0.05);

        @include desktop {
            padding-top: 120px !important;
            font-weight: 200;
            font-size: 3rem;
        }

        @include non-desktop {
            padding-top: 60px !important;
            padding: 30px;
            font-size: 2rem;
            font-weight: 400;
        }
    }

    .description {
        @include non-desktop {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(155, 182, 248, 1) 32%, rgba(155, 182, 248, 1) 100%);
        }
    }

    p {
        padding: 5px 30px;

        @include non-desktop {
            font-weight: 400 !important;
            font-size: 2.2vh !important;
        }

        @include desktop {
            max-width: 500px;
            font-size: 1.3vw !important;
            font-weight: 300 !important;
            line-height: 1.2;
        }
    }

    position: relative;
    box-sizing: border-box;
}

.contentWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100vw;

    @include desktop {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}

.timeWrapper {
    position: absolute;
    font-size: 2rem !important;
    opacity: .5;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #9bb6f899;
    border-radius: 10px;

    @include desktop {
        right: 100px;
        bottom: 80px;
    }

    @include non-desktop {
        padding-left: 30px;
        bottom: 60px;
    }

    span {
        padding-top: 12px;
    }
}

.groupWrapper {
    position: absolute;
    font-size: 2rem !important;
    opacity: .5;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #9bb6f899;
    border-radius: 10px;

    @include desktop {
        right: 100px;
        bottom: 120px;
    }

    @include non-desktop {
        padding-left: 30px;
        bottom: 140px;
    }

    span {
        padding-top: 12px;
    }
}

.orderButton {
    @include desktop {
        margin-top: 10px !important;
        margin-left: 30px !important;
    }
}