@import '../../utils/library';

.wrapper {
    width: 100vw;
    height: 100vh;
    background-color: $main-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $main-color;
}