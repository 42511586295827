@import '../../utils/library';

.wrapper {
    background-color: $main-color;
    color: #fff;
    padding: 30px;
    font-weight: 300;

    p {
        padding: 0 0 10px;
    }

    .header {
        font-size: 1.5rem;
    }
    .subheader {
        font-size: 1.5rem;
    }

    a {
        color: #fff;
    }
}