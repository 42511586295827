@import '../../../../utils/library';

.commentBlock {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.textList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 70vw;
    gap: 3px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    margin-right: 30px;

    @include desktop {
        max-width: 600px;
    }
}

.commentAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.commentContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f7f7f7;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 10px 15px;
}

.commentText {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
}

.commentMeta {
    font-size: 12px;
    color: #8c8c8c;
}