.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #d495aa;
    background-image: url('../../assets//images/shoes.png');
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: 30% 90%;
    color: white;
    text-align: center;
}