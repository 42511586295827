@import '../../utils/library';

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #d495aa77;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    backdrop-filter: blur(10px);
}

.popup {
    @include slide-in-top();
    background-color: #62aaee;
    box-shadow: 0 4px 9px rgba(77, 77, 77, 0.25);
    border-radius: 10px;

    @include desktop {
        padding: 30px 30px 500px;
    }

    @include non-desktop {
        padding: 30px 30px 300px;
    }

    margin: 20px;
    background-image: url('../../assets/images/flowers.png'), url('../../assets/images/about-bg.jpg');
    background-repeat: no-repeat, no-repeat;
    background-size: 100%, 130%;
    background-position: bottom right, center 90%;
    color: #fff;
    // text-shadow: 2px 2px 2px #000;
    font-weight: 400;
    position: relative;

    @include desktop {
        max-width: 600px;
    }

    h2 {
        margin-bottom: 20px;
    }

    p {
        padding: 10px 0;
        font-size: 1rem;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom right, #ffffff00, #fff, #ffffff00);
        background-size: 200% 200%;
        animation: sunray 5s ease-in-out infinite;
        pointer-events: none;
        mix-blend-mode: overlay;
        opacity: .8;
        border-radius: 10px;
    }
}


@keyframes sunray {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0 0;
    }
}

.button {
    position: absolute;
    z-index: 1000;
    bottom: 30px;
    left: 30px;
    right: 30px;
    background-color: #ffffffbb;
    border: none;
    outline: none;
    padding: 20px;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 10px;
    color: $secondary-color;
    cursor: pointer;
}