$main-color: #d495aa;
$main-color-dark: rgb(110, 23, 52);
$secondary-color: #61aaee;
$secondary-dark-color: #17538b;

@mixin desktop {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin non-desktop {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: 1255px) and (min-width: 991px) {
    @content;
  }
}

@mixin non-mobile {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 770px) and (max-width: 991px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 769px) {
    @content;
  }
}

@mixin sectionHeader($color: #fff) {
  color: $color;
  margin-bottom: 40px;
  margin-left: 30px;
  font-weight: 200;
  text-transform: uppercase;

  @include desktop {
    font-size: 4rem;
  }

  @include small-desktop {
    font-size: 3rem;
  }

  @include non-desktop {
    font-size: 2rem;
  }
}

@mixin sectionWrapper($background-color: 'unset') {
  // height: calc(var(--vh) * 100);
  overflow: hidden;
  background-color: $background-color;
  position: relative;
  min-height: 100vh;

  @include mobile {
    min-height: calc(100vh - 50px) !important;
  }
}

@mixin slide-out-right {
  animation: slide-out-right .5s cubic-bezier(.55, .085, .68, .53) both
}

@mixin slide-out-left {
  animation: slide-out-left .5s cubic-bezier(.55, .085, .68, .53) both
}

@mixin slide-out-top {
  animation: slide-out-top .5s cubic-bezier(.55, .085, .68, .53) both
}

@mixin slide-out-bottom {
  animation: slide-out-bottom .5s cubic-bezier(.55, .085, .68, .53) both
}

@mixin slide-in-top {
  animation: slide-in-top .5s cubic-bezier(.25, .46, .45, .94) both
}

@mixin slide-in-bottom {
  animation: slide-in-bottom .5s cubic-bezier(.25, .46, .45, .94) both
}

@mixin slide-in-right {
  animation: slide-in-right .5s cubic-bezier(.25, .46, .45, .94) both
}

@mixin slide-in-left {
  animation: slide-in-left .5s cubic-bezier(.25, .46, .45, .94) both
}

.slide-out-right {
  @include slide-out-right()
}

.slide-out-left {
  @include slide-out-left()
}

.slide-out-top {
  @include slide-out-top()
}

.slide-out-bottom {
  @include slide-out-bottom()
}

.slide-in-top {
  @include slide-in-top()
}

.slide-in-bottom {
  @include slide-in-bottom()
}

.slide-in-right {
  @include slide-in-right()
}

.slide-in-left {
  @include slide-in-left()
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1
  }

  100% {
    transform: translateX(1000px);
    opacity: 0
  }
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1
  }

  100% {
    transform: translateX(-1000px);
    opacity: 0
  }
}

@keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1
  }

  100% {
    transform: translateY(-1000px);
    opacity: 0
  }
}

@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1
  }

  100% {
    transform: translateY(1000px);
    opacity: 0
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0
  }

  100% {
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0
  }

  100% {
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0
  }

  100% {
    transform: translateX(0);
    opacity: 1
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0
  }

  100% {
    transform: translateX(0);
    opacity: 1
  }
}