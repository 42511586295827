@import '../../../../utils/library';

.wrapper {
    @include sectionWrapper($main-color);
    background-image: url('../../../../assets//images/contacts_bg.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;
    padding: 30px;
    height: 100vh;

    @include desktop {
        background-position-x: right;
        background-position-y: bottom;
    }

    @include non-desktop {
        background-position-y: 88%;
    }

    h2 {
        @include sectionHeader();
        padding-top: 60px !important;
    }

    .tiktok {
        width: 20px;
        height: 20px;
        filter: invert(1);
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include desktop {
            max-width: 600px;
            padding-left: 30px;
            font-size: 1.2rem;
        }

        li {
            display: grid;
            grid-template-columns: 30px auto;
            gap: 10px;
            color: #fff;
            align-items: center;
            border: 1px solid #ffffff33;
            padding: 10px;

            a {
                color: #fff;
                text-decoration: none;
            }

            &:hover {
                background-color: #ffffff33;
            }
        }
    }
}

.author {
    color: #fff;
    position: absolute;
    bottom: 30px;
    left: 60px;

    a {
        color: #fff;
    }
}