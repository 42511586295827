.actions {
    svg {
        cursor: pointer;
    }
}

.tableRow {
    &:hover {
        background-color: #ececec;
    }
}