@import '../../../../utils/library';

.wrapper {
    @include sectionWrapper(#9bb6f8);

    @include desktop {
        background-color: #9bb6f8 !important;
        background-image: url('../../../../assets/images/subscription_splash.jpg');
        background-size: cover;
        // background-position-x: 20vw !important;
        background-position-y: -50vh;
        background-repeat: no-repeat;
        min-height: 100vh;
        height: 100vh;
        width: calc(100vw - 12px);
    }

    @include non-desktop {
        background-image: url('../../../../assets/images/subscription_splash.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100vh;
        width: 100%;
    }

    h2 {
        @include sectionHeader();
        width: 100%;
        margin: 0;


        @include desktop {
            text-align: start;
            padding-top: 120px !important;
            padding-left: 50px;
        }

        @include non-desktop {
            text-align: center;
            padding-top: 40px;
        }
    }

    h3 {
        @include desktop {
            text-align: start;
            padding-left: 30px;
        }

        @include non-desktop {
            text-align: center;
        }
    }

    .swiperWrapper {
        @include desktop {
            background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 50%, #9bb6f8 70%, #9bb6f8 100%)
        }

        @include non-desktop {
            background: linear-gradient(-5deg, rgba(0, 0, 0, 0) 80.7%, #9bb6f8 80.7%, #9bb6f8 100%);
        }

        @include small-desktop {
            background: linear-gradient(-5deg, rgba(0, 0, 0, 0) 80.7%, #9bb6f8 80.7%, #9bb6f8 100%);
        }

        .swiperSlide {
            position: relative;
            width: 100vw;
            height: 100vh;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            box-sizing: border-box;
            background-color: #9bb6f8;
            overflow: hidden;

            @include desktop {
                padding: 0 60px;
            }

            p {
                font-weight: 200;
                font-size: 1.2rem;
                text-align: justify;
            }
        }

        .swiperSlideTitle {
            background-color: transparent !important;
        }
    }
}