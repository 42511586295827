@import '../../utils/library';

.wrapper {
    padding: 20px 20px 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include desktop {
        min-width: 500px;
    }
}