@import '../../utils/library';

.wrapper {
    background-color: $main-color;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    flex-direction: column;

    h2 {
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 200;
        padding-bottom: 20px;
    }

    p {
        padding: 30px;

        @include desktop {
            max-width: 500px;
        }
    }

    a {
        color: #fff;
    }
}