@import '../../utils/library';

.profileWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.toolbar {
 
}