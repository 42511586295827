@import '../../../../utils/library';

.addButtonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 40px;
    padding-bottom: 10px;
}

.wrapper {
    max-height: calc(100vh - 350px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .subItem {
        margin: 5px 0;
        position: relative;

        .subItemBody {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.active {
    background-color: rgb(188, 223, 255) !important;
}

.nonActive {
    background-color: rgb(179, 179, 179) !important;
    opacity: .5;
}

.visits {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.deleteButton {
    cursor: pointer;
    padding: 5px;
    box-sizing: content-box;
    opacity: .5;
    &:hover {
        background-color: #fff;
        border-radius: 50%;
        color: #f00;
        opacity: 1;
    }
}

.editButton {
    cursor: pointer;
    padding: 5px;
    box-sizing: content-box;
    opacity: .5;
    &:hover {
        background-color: #fff;
        border-radius: 50%;
        color: $secondary-color;
        opacity: 1;
    }
}

.addButton {
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    padding: 5px;
    box-sizing: content-box;
    &:hover {
        background-color: #fff;
        border-radius: 50%;
        color: #ffbd2f;
    }
}

.controlSection {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
}