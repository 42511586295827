@import '../../../../utils/library';

.wrapper {
    @include sectionWrapper($main-color);
    height: 100vh;

    h2 {
        @include sectionHeader(#fff);
        margin: 70px 60px 0;
    }

    h3 {
        margin-inline-start: 60px;
        color: $secondary-dark-color;
        font-family: 'Cocoa Gothic Small Caps';
        text-transform: uppercase;

        @include desktop {
            font-size: 2rem;
            font-weight: 400;
        }
    }

    p {
        position: absolute;
        bottom: 120px;
        padding: 0 20px;
        color: #fff;
        font-weight: 300;
        z-index: 2;
        line-height: 1.2;
        font-size: 1.2rem;

        @include desktop {
            font-size: 1.7rem;
            width: 90vw;
            right: 0;
            text-align: end;

        }

        @include non-desktop {
            max-width: 500px;
            text-align: justify;
        }
    }
}

.swiperWrapper {
    height: 100vh;

    @include non-desktop {
        background: linear-gradient(0deg, $main-color 50%, $secondary-color 50%);
    }
}

.swiperSlide {}

.slideBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 150%;
    background-position: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    @include desktop {
        // background-size: 150vh !important;
        background-position: right center;
    }

    @include non-desktop {
        clip-path: polygon(0 17%, 100% 17%, 100% 100%, 0 100%);
    }
}

.slideBackground {}

.slideBackground[id=slide-bg-1] {
    background-image: url("../../../../assets/images/why_1.jpg");

    @include desktop {
        transform: translateX(20vw);
    }
}

.slideBackground[id=slide-bg-2] {
    background-image: url("../../../../assets/images/why_2.jpg");
}

.slideBackground[id=slide-bg-3] {
    background-image: url("../../../../assets/images/why_3.jpg");
}

.slideBackground[id=slide-bg-4] {
    background-image: url("../../../../assets/images/why_4.jpg");

}

.slideBackground[id=slide-bg-5] {
    background-image: url("../../../../assets/images/why_5.jpg");
}

.slideBackground[id=slide-bg-6] {
    background-image: url("../../../../assets/images/why_6.jpg");
}

.swiperBody {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    p {
        position: absolute;

        @include non-desktop {
            background-color: #d495aa99;
            backdrop-filter: blur(2px);
            bottom: 0;
            padding: 30px 30px 70px;
        }

        @include desktop {
            left: 40px;
            top: 50vh;
            max-width: 25vw;
            text-align: start;
            font-size: 1.5vw !important;
        }

    }
}

.gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
    background-image: linear-gradient(90deg, $secondary-color 0%, transparent 50%);

    @include non-desktop {
        display: none;
    }
}