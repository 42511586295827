@import "../../../utils/library";

.sectionWrapper {
  height: 100vh;
  position: relative;
}

.mobileMenuHeader {
  position: fixed;
  z-index: 101;
  box-sizing: border-box;
  padding: 10px;
  // background-color: #00000033;
  width: 100vw;
  display: flex;
  justify-content: space-between;
}

.mobileNawWrapper {
  .mobileMenuIcon {}

  .mobileNav {
    backdrop-filter: blur(5px);
    background-color: #00000033;
    position: fixed;
    z-index: 100;
    top: 0;
    padding-top: 60px;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in-out;

    ul {
      flex-direction: column;

      li {
        border-bottom: 1px solid #ffffff11;
      }
    }
  }
}

.navWrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  background-color: $main-color;
  z-index: 100;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) .3s both;
  font-weight: 300;
}

.authSection {
  display: flex;
  gap: 15px;

  @include desktop {
    margin-right: 40px;
  }

  @include non-desktop {
  
  }

  .basketIcon {
    cursor: pointer;
  }

  .loginButton {
    background-color: transparent;
    color: #fff;
    border: none;
    outline: none;
    font-family: "Cocoa Gothic";
    font-size: 1rem;
    padding: 5px 10px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #ffffff33;
    }
  }

  .signUpButton {
    background-color: #fff;
    color: $secondary-color;
    border: none;
    outline: none;
    font-family: "Cocoa Gothic";
    font-size: 1rem;
    border-radius: 5px;
    padding: 5px 10px;
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: #ffffff33;
    }
  }
}

nav {
  ul {
    list-style-type: none;
    margin: 0 0 0 20px;
    padding: 0;
    font-family: "Cocoa Gothic";
    color: #fff;
    display: flex;
    text-transform: uppercase;

    @include desktop {
      font-size: 1rem;
    }

    @include non-desktop {
      font-size: 1.2rem;
    }

    li {
      transition: all 0.3s ease-in-out;

      @include desktop {
        padding: 20px 1.5vw;
      }

      @include non-desktop {
        padding: 20px 30px;
      }

      &:hover {
        background-color: #ffffff33;
        cursor: pointer;
      }
    }
  }
}

.subscriptionsSectionWrapper {
  background-color: $secondary-color;

  h2 {
    @include sectionHeader($main-color-dark);
  }

  p {
    max-width: 500px;
    line-height: 1.5;
    font-size: 1.2rem;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px) translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(40px) translateY(-100px);
    opacity: 1;
  }
}

@keyframes slide-in-left-mobile {
  0% {
    transform: translateX(-1000px) translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(35vw) translateY(-100px);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    transform: translateX(-240px);
    opacity: 1;
  }
}

@keyframes slide-in-right-mobile {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    transform: translateX(-200px);
    opacity: 1;
  }
}

@keyframes flip-in-ver-right {
  0% {
    transform: rotateY(-80deg);
    opacity: 0;
  }

  100% {
    transform: rotateY(0);
    opacity: 1;
  }
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.96);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(1.1);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.92);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1.1);
    animation-timing-function: ease-out;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}