@import "../../../../utils/library";

.wrapper {
    @include sectionWrapper();

    @include desktop {
        background: $main-color;
        background-image: url('../../../../assets/images/splash.png');
        background-size: 100vh;
        background-position: center center !important;
    }

    @include small-desktop {
        background: $main-color;
        background-image: url('../../../../assets/images/splash.png');
        background-size: 70vh;
        background-position: center center !important;
    }

    @include non-desktop {
        background-image: url('../../../../assets/images/splash.png');
        background-color: $main-color;
        background-size: 80vh;
        background-position-y: 200px;
        background-position-x: center !important;
    }

    background-repeat: no-repeat !important;
}

.title {
    color: #fff;
    font-weight: 200;
    text-transform: uppercase;
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Cocoa Gothic Small Caps';

    @include desktop {
        font-size: 7vw;
        max-width: calc(7vw * 7);
        left: 30px;
        top: 60px;
    }

    @include small-desktop {
        font-size: 5vw;
        left: 30px;
        top: 90px;
    }

    @include mobile {
        font-size: 15vw;
        width: 100%;
        top: 80px;
        text-align: center;
        margin-left: 7px;
    }
}