@import '../../utils/library';

.wrapper {
    background-color: $main-color;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-image: url('../../assets/images/legs_subs.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;

    
    @include non-desktop {
        flex-direction: column;
    }

    h1 {
        color: #fff;
        text-transform: uppercase;
        font-weight: 200;
        font-size: 3rem;
    }
}

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @include non-desktop {
        margin: 20px;
    }

    @include slide-in-top();
}