@import '../../utils/library';

.wrapper {
    padding: 40px;
    max-width: 400px;
}

.datePicker {
    width: 100%;
}

.privacyPolicyLabel {
    font-size: .87rem;

    a {
        color: $main-color;
    }
}