@import '../../utils/library';

.wrapper {
    background-color: $main-color;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
    gap: 20px;
    box-sizing: border-box;
    padding: 30px;

    h2 {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 200;
    }
}