@import '../../../../utils/library';

.wrapper {
  @include sectionWrapper($secondary-color);

  h2 {
    @include sectionHeader();
  }

  .header {
    position: absolute;
    z-index: 10;
    padding-top: 60px;
    font-weight: 200;
    font-family: 'Cocoa Gothic';
    background: transparent;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 30px;
  }
}

.swiperSlide {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
}