@import '../../../../../../utils/_library';

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    h2 {
        padding-top: 60px !important;
        padding-left: 0 !important;
    }

    h3 {
        @include desktop {
            padding: 10px 0 50px !important;
        }

        @include small-desktop {
            padding: 10px 0 30px !important;
        }

        @include non-desktop {
            padding: 10px 0 90px !important;
        }
        font-weight: 300;
        font-size: 1rem;
        text-align: center;
        text-transform: uppercase;
    }

    ul {
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        @include desktop {
            width: 300px;
        }

        @include non-desktop {
           display: flex;
           flex-direction: column;
           width: 100vw;
           box-sizing: border-box;
           padding-right: 30vw;
        }

        li {
            margin: 5px 0;
            font-size: 1.1rem;
            text-transform: uppercase;
            text-align: center;
            padding: 10px 0;
            width: 100%;

            @include non-desktop {
                background-color: #9bb6f8bb;
                color: #fff;
                font-weight: 300;
            }

            @include desktop {
                background-color: #ffffffdd;
                color: #9bb6f8;
                font-weight: 400;
                cursor: pointer;

                &:hover {
                    background-color: #ffffff;
                }
            }
        }
    }
}